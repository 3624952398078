/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-06 16:19:34
 * @LastEditors: pj
 * @LastEditTime: 2023-05-18 16:23:26
 */
import request from '@/plugins/request'

export function userList (data = {}) {
  return request({
    url: '/api/user/info/getRegionList',
    method: 'POST',
    data,
  })
}

export function getCityList (data = {}) {
  return request({
    url: '/api/user/info/getCityList',
    method: 'POST',
    data,
  })
}